.w-block1{
    @apply max-w-[500px]
}
.title-level1{
    /* @apply font-extrabold text-[34px] uppercase leading-[44px] w-[80%%]  */
    @apply text-[35px] font-extrabold uppercase w-[80%] leading-[42px]
}
.text-level1{
    /* @apply font-light text-[27px] uppercase */
    @apply text-[29px] font-light uppercase
}

.label-level2{
    @apply text-[19px] font-bold leading-[27px]
}
.text-skin-inverted .label-level2{
    color: #fff;
}
.title-level2{
    @apply  text-[28px] leading-[35px] font-semibold
}
.label-level2{
    color: var(--bg-inverted);
}
.color-inverted{
    color: var(--bg-inverted);
}
.text-level2{
    @apply font-light mt-[5px] text-[19px] leading-[31px]
}
.btn{
    background-color: #000;
    color: #fff;
}
.title-resource{
    @apply text-[14px] italic
}
/* .px-page{
    @apply px-[120px]
} */
.pl-page{
    @apply pl-[120px]
}
.pr-page{
    @apply pr-[120px]
}
.pt-page{
    @apply pt-[157px]
}
.bg-block-inverted{
    background-color: #000;
    color: #fff;
  
}
.py-block{
    padding-top: 188px;
    padding-bottom: 188px;
}
.pb-block{
    padding-bottom: 188px;
}
.pt-block{
    padding-top: 188px;
}

.py-block-lg{
    padding-top: 220px;
    padding-bottom: 220px;
}
.bg-block-inverted .title-level2, .bg-block-inverted .color-inverted, .bg-block-inverted .label-level2{
    color: inherit
}
.space-between-blocks{
    @apply space-y-[100px]
}
.space-between-same-block{
    @apply space-y-8
}
.space-between-main-blocks{
    @apply space-y-[120px]
}
.space-between-2cols{
    @apply gap-x-[30px]
}
.bg-container{
    background-color: #ededed;
}
.selected{
    position: relative;
}
.selected::after{
    content: '';
    width: 100%;
    width: 100%;
    height: 8px;
    background: #000;
    display: block;
    top: 47px;
    position: absolute;

}
.title-main{
    @apply text-[47px] leading-[61px] font-bold uppercase;
}
.text-main{
    @apply font-light text-[47px] leading-[61px] uppercase;
}
.btn-gray{
    @apply text-level2 block  bg-gray-300 px-7 py-2.5  rounded-lg
}
.btn-gray{
    background-color: '#606060';
}
.max-w-page{
    max-width: 1200px;
}