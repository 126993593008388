@import url('theme.css');
@import url('pageType.css');
@import 'theme.css';


*{
  font-size: 14px;
  line-height: initial;
} 

body{
  -webkit-user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  cursor: default;
  color: var(--text-base);
  overflow: hidden;

}
::-webkit-scrollbar-track {
/* -webkit-box-shadow: inset 0 0 6px     rgba(0,0,0,0.3);  */
border-radius: 20px;

}
::-webkit-scrollbar {
    width: 8px;
}

/* Add a thumb */
::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: var(--bg-hover) !important; 

}
.scroll::-webkit-scrollbar-thumb{
    position: relative;
    right: -20px;
}

  
#root, #root>div{
    height: 100%;
}
input[type="color"] {
	-webkit-appearance: none;

}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
}


.spacing-y{
    @apply py-16;
}
.spacing-x{
    @apply px-16;
}
.title-block1{
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 28px;
}
.text-block1{
    @apply text-xl font-light;
}
.block2{
    max-width: 250px;

}
.title-block2{
    font-size: 20px;
    line-height: 151%;
    @apply font-bold;
}
.text-block2{
    font-size: 17px;
    line-height: 151%;
}
.title-block3{
    font-size: 15px;
    @apply font-bold;
}
.text-block3{
    @apply font-normal;
}

.space-between-lines{
    @apply space-y-5;
}
.text-group{
    @apply text-[13px] font-bold uppercase;
  }
  .text-title{
    font-size: var(--font-size-title);
    font-weight: 500;
    line-height: 17px;
  }
.ellipsis-1{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-6{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-5{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-4{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-3{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ellipsis-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}



  .ant-tree-list-scrollbar-thumb{
    background: var(--bg-hover) !important;
  }
  .ant-tree-list-scrollbar.ant-tree-list-scrollbar-show{
    display: block !important;
    right: -1px !important;
  }
  .ant-tree-indent-unit{
    width: 31px !important;
  }
  .ant-tree-node-content-wrapper{
    display: flex;
    align-items: center;
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .ant-tree-list .ant-tree-iconEle.ant-tree-icon__customize{
    display: flex !important
  }
  .ant-tree.ant-tree-list {
    @apply grid w-full;
  }
  .ant-tree .ant-tree-treenode,
  .ant-tree .ant-tree-list .ant-tree-node-content-wrapper{
    width: 100% !important;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected,
  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{
    background-color: var(--bg-inverted) !important;
    color: var(--text-inverted) !important;
    border-radius: 0.25rem !important;
  }
  .ant-tree-treenode{
    padding-bottom: 0 !important;
  }
  .ant-tree-node-content-wrapper{
 
    @apply !px-1.5 !py-[1px] !transition-none border-[1px] border-transparent
  }
.ant-tree.ant-tree-title {
@apply whitespace-nowrap;
}
.ant-tree.ant-tree-treenode {
@apply w-full p-0;
}

.ant-tree.ant-tree-node-content-wrapper {
@apply w-full flex hover:bg-teddra-gray-100 hover:text-black rounded-none rounded-l py-1 pl-2 ;

}
.ant-tree .ant-tree-node-selected {
@apply bg-teddra-red text-white;
}

.ant-tree .ant-tree-iconEle {
@apply flex items-center w-max;
}

.ant-tree .ant-tree-switcher {
@apply flex items-center justify-center;
}

.ant-tree .ant-tree-list .ant-tree-node-content-wrapper.ant-tree-node-selected svg {
fill: #fff ;
}
.sidebar .ant-tree-list-holder {
    padding-top: 0;
    overflow-x: hidden;
}
.sidebar .ant-tree-title {
    flex: 1;
}
.ant-tree .ant-tree-node-content-wrapper:hover{
    background: var(--bg-hover);
    border: 1px solid var(--border-hover)
  
   
  }
  .ant-tree-treenode{
    color: var(--text-base);
    fill: var(--text-base);
  }
  

  .bg-image{
    background:  rgba(14 ,94, 169 , 0.3) url('../../public/images/img1.jpg') ;
    background-size: cover;
    background-position: 55% 50%;
  }

  .ant-drawer-body{
    padding: 0 !important;
  }
  .ant-drawer-header{
    display: none;
  }
  .ant-modal-body{
    padding: 0;  
  }
  .ant-modal-content{
    border-radius: 16px;
    box-shadow: 0 4px 13px rgba(0,0,0,0.3);
    background-color: transparent;
    background-color: #fff;
  }
  .ant-modal-mask{
    backdrop-filter: blur(4px) ;
    height: calc(100vh) !important;
    top: 0 !important;
    width: calc(100vw) !important;
    left: 0 !important;
    z-index: 1000 !important;
    background: rgba(255, 255, 255, 0.45) !important;
  }
  .ant-modal{
    height: 100vh;
    width: 70% !important;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;

    /* top: 50%; */
    /* transform: translateY(-50%);
    transition: 0.5 all; */
  }
  @media only screen and (max-width:1550px){
    .ant-modal{
      width: 80% !important;
    }
  }
  @media only screen and (max-width:1330px){
    .ant-modal{
      width: 87% !important;
    }
  }
  
  .mask{
    backdrop-filter: blur(8px) ;
  }
  .ant-tree-switcher{
    width: 17px !important;
  }
  .slick-list,.slick-track,.ant-carousel,.slick-slide > div{
    height: 100%;
  }
  .vertical-timeline{
    padding-top: 0 !important;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-icon{
    margin-left: -21px !important;
  }
  .vertical-timeline-element-content p{
    margin-top: 0 !important;
  }
  .vertical-timeline::before{
    width: 0.2px !important;
  }
  .vertical-timeline-element-icon svg {
    display: block;
    width: 39px;
    height: 43px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -23px;
    margin-top: -22px;
  }
  .vertical-timeline-element-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 9px !important;
  }
  .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content{
    margin-right: 18px;
  }
  .vertical-timeline-element.vertical-timeline-element--left .vertical-timeline-element-content{
    margin-left: 18px;
  }

.active-lng,
.active{
  position: relative;
}
.active-lng::after,
.active::after{
  content: '';
  width: 100%;
  position: absolute;
  background-color: var(--bg-inverted);
  height: 3px;
  left: 0;
  top: 24px;
}
.active::after{
  top: 54px;
  bottom: 0;
}
.active-lng.menu::after{
  background-color: #fff !important;
  top: 27px;
}
.calendly-inline-widget{
  height: 100% !important;
}
iframe{
  outline: none !important;
}
.ant-collapse,
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  color: var(--text-base) !important;
}
.ant-collapse-content{
  color: inherit !important;
}
.ant-collapse-header{
  padding-left: 0 !important;
}
.ant-collapse-header-text{
  padding-left: 15px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  /* padding: 0 0 5px 0 !important; */
  font-weight: 700;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header, .ant-collapse-expand-icon, .ant-select-selection-placeholder {
  display: flex;
  align-items: center !important;
}
.ant-collapse > .ant-collapse-item {
  border: none !important;
}
 .ant-collapse {
  width: 100% !important;
  background-color: inherit !important;
  padding-top: 3px !important;
}
 .ant-collapse-expand-icon {
  width: 15px;
}
 .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0 !important;
}
 .ant-collapse-content-box{
  padding-right: 0 !important;
  padding-left: 6px !important;
}
.ant-collapse-header{
  padding-top: 7px !important;
  padding-bottom: 4px !important;
}
.px-0 .ant-collapse-header{
  padding-left: 0 !important;
}