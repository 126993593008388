@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@layer base{

    .teddra-light-gray{
      --text-base: #181a1e ;  /* #000000b3 */
      --text-inverted:rgb(242, 242, 242) ;
      --text-muted: 0, 0, 0;

      --window-shadow:0px 0px 8px rgba(0,0,0,0.2);
      --drop-shadow:2px 2px 6px #041c33;
      --panel-shadow-color: 0, 0, 0 ;

      --text-main-base:rgb(149, 149, 149);
      --text-main-shadow:none;

      --bg-menu:#fff;
      --bg-base: #ececec ;
      --bg-inverted: #204190; 
      --bg-inverted-main:#204190; 
      --bg-inverted-transparent:rgba(14 ,94 ,169,0.7); 


      --bg-muted: 249, 249, 249;
      --bg-muted-inverted:  212 ,212, 212;/*main*/

      --bg-hover:  rgba(144, 201, 255,0.2);
      --border-hover:  rgba(144, 201, 255,0.3);

      --bg-input:#fff;
      --bg-journal: rgb(122 122 122);
      
      --border-base: 241 241 241;
      --border-muted: 245 245 245;/*same as text-inverted*/
      --border-sub-windows:rgb(199 199 199 / 40%);
      --border-dark: 59 59 59;
      --hover-dark:82, 82 ,82;

      --bg-base-windows: #fff;
      --bg-main-window:  #f7f7f7 ;
      --bg-sidebar:   #fff;
      --bg-bars :#fff;
      /* #dadada */
      --icon-bar-height : 39px;
      --input-height: 35px;

      --icon-color: 149, 149, 149,1;
      --icon-color-inverted:255,255,255;/*main*/


      --font-size-standard: 13px;
      --font-size-title: 14px;

      --font-size-sm: 12px;
      --font-size-group: 13px;
      --modal-bg:#e7e7e7;
      --modal-border:#ccc;
      --bg-main-bar:rgb(14 94 169 / 90%);
      --bg-top-bar:rgb(255 255 255);

      
      /* #e6e6e6 */
    }
   
   
    .bg-skin-transparent{
      background: rgba(var(--bg-muted), 1)
    }
    
  }
  .bg-mainbar{
    background-color: var(--bg-main-bar);
  }
  .bg-topbar{
    background-color: var(--bg-top-bar);
  }
  .modal-bg{
    background-color: var(--modal-bg) !important;
  }
  .modal-border{
    border-color: var(--modal-border) !important;
  }
  .disabled,
  .ant-dropdown-menu-item-disabled{
    opacity: 0.2 !important;
  }
  .disabled\:fill-skin-muted:disabled,
  .disabled\:text-skin-muted:disabled{
    opacity: 0.2 !important;
    color: #000 !important;
  }
  [type=checkbox], [type=radio]{
    color: rgba(0,0,0,0.5);
    border-radius: 3px !important;
  }
  [type=checkbox]:focus, [type=radio]:focus{
    outline-offset: 0px !important;
    box-shadow: none !important;
  }

.fill-bg-main{
  fill: var(--bg-main-window) !important;
}
.text-bg-main{
  color: var(--bg-main-window) !important;
}
.bg-text-base{
  background: var(--text-base);
}
.text-main-base{
  color: var(--text-main-base);
}
.text-main-shadow{
  text-shadow: var(--text-main-shadow);
}
.bg-sub-windows{
 background-color: var(--bg-base-windows); 
}

.text-standard{
  font-size: var(--font-size-standard);
}
.text-title-window{
  font-size: var(--font-size-title);
  font-weight: 500;
}
.text-title{
  font-size: var(--font-size-title);
  font-weight: 500;
  line-height: 17px;
}
.text-groupe{
  font-size: var(--font-size-group);
  font-weight: 900;
  text-transform: uppercase;
}
.text-small{
  font-size: var(--font-size-sm);
}
.text-verysmall{
  font-size: 10px;
}
.text-muted{
  font-size: var(--font-size-sm);
  color: rgb(var(--text-muted));
  font-weight: 300;
}
.text-column{
  font-size: var(--font-size-standard);
}

.border-sub-windows{
  border: 1px solid var(--border-sub-windows)
}
.bg-gray{
  background-color: #f8f8f8;
}
select{
  height: var(--input-height);
}
body{
    @apply teddra-light-gray;
}
*{
  font-size: var(--font-size-standard);

} 
/* p,span,a{
  font-size: inherit;
} */
body{
  fill: rgba(var(--text-base));
  color: rgba(var(--text-base));
  font-family: 'Segoe UI';
  font-weight: 400;
}

svg{
  /* color: currentColor; */
  fill: currentColor
}
svg[fill="transparent"]{
  fill: transparent !important;
}
.fill-icon-base{
  fill: rgba(var(--icon-color));
  color: rgba(var(--icon-color));

}

.text-icon-base{
  color: rgba(var(--icon-color)) !important;

}
.bg-gray{
  background: #e8e8e8;
}

.bg-input{
  background-color: var(--bg-input);
}

.border-color{
@apply border-teddra-gray-100 border-opacity-50;
}

.border-main, .border-base{
  border-color: rgb(var(--border-base))   !important;
}
.border-muted{
  border-color: rgb(var(--border-muted))   !important;
}
.bg-hover-transparent{
  border: 1px solid transparent;
}
.bg-skin-fill-inverted{
  border: 1px solid transparent;
}
.bg-hover-transparent:hover{
  /* background-color: rgb(var(--bg-muted)); */
  background: var(--bg-hover);
  border: 1px solid var(--border-hover)
  /* border: 1px solid transparent; */
}
.bg-hover:hover{
  background: rgba(144, 201, 255,1);
  /* border: 1px solid var(--border-hover) */
}
.bg-selected-secondary{
  background-color: var(--bg-inverted) !important;
  color: var(--text-inverted);
  fill: var(--text-inverted);
}

.text-bg-selected{
  color:var(--bg-inverted)
}
.fill-bg-selected{
  fill:var(--bg-inverted-main)
}
.shadow-details{
  box-shadow: 1px 0px 20px rgba(0,0,0,0.2)
}
.icon-md{
@apply w-[23px] h-[23px];
}
.icon-sm{
@apply w-[20px] h-[20px];
}
.icon-mini{
@apply w-[15px] h-[15px];
}
.icon-lg{
@apply w-[40px] h-[40px];
}
.bar-h{
    @apply h-[42px];
}
.bar-h-mini{
  @apply h-[30px]
}
.teddra-rounded{
    border-radius: 10px;
}
.teddra-shadow{
  /* box-shadow: 0px -1px 10px 2px rgba(161,161,161,0.5); */
  /* filter: drop-shadow(var(--window-shadow)) ; */
  filter:drop-shadow(2px 2px 7px rgba(00,0,0,0.2))

  /* 0 2px 10px 0px rgb(105 130 187 / 40%) */
}
.bg-main-window{
  background: var(--bg-inverted) !important;
}

.teddra-drop-shadow{
  /* box-shadow: 0px -1px 10px 2px rgba(161,161,161,0.5); */
  filter: drop-shadow(var(--drop-shadow));
}

.height-with-6bars{
  height: calc(100vh - 263px);
}
.height-with-4bars{
    height: calc(100vh - 207px);
    /* height: calc(100vh - 183px); */

}
.height-with-2bars{
    height: calc(100vh - 85px);
}
.height-with-1bars{
  height: calc(100vh - 47px);
}
.height-with-3bars{
  height: calc(100vh - 135px)
}


.icon-color{
  fill: rgba(var(--icon-color));
}
.icon-bg{
  fill: rgba(255,255,255,1);
}

.icon-text{
  color: rgba(var(--icon-color));
}
.icon-border{
  border-color: rgba(255,255,255,1);
}


.icons-bar-r{
  padding-left: 5px;
}

input{
  height: var(--input-height);
  font-size: var(--font-size-standard) !important;
  background-color: var(--bg-input);

}
textarea{
  font-size: var(--font-size-standard) !important;

}
.input-h{
  height: var(--input-height);
}
.hover-lg{
  @apply p-3 rounded;
}
.text-group{
  @apply text-[13px] font-bold uppercase;
}


[type=text]:not(.border), [type=email], [type=url], [type=password], [type=number], [type=date], [type=datetime-local], [type=month], [type=search], [type=tel], [type=time], [type=week], [multiple], textarea:not(.border), select{
  border-color: inherit !important;
  outline: none;
  box-shadow: none !important;
  outline-offset: 0;
  outline-width: 0;
  background-color: var(--bg-input);
}
[type=text]:focus,textarea:focus{
  outline: none !important;
  outline-offset: 0 !important;
  border-color: inherit !important;
  box-shadow: none !important;
}
input::placeholder,textarea::placeholder{
  color: rgb(var(--text-muted));
  font-size: var(--font-size-standard);
}
.ant-select-selector{
  height: var(--input-height) !important;
}
.ant-select{
  color: inherit !important;
}
.ant-select-selection-placeholder{
  color: var(--text-base) !important;
}

.injected-svg{
  width: 100%;
  height: 100%;
}

.border-fill-inverted{
  border-color: var(--bg-inverted);
}

.fil1{
  fill: inherit !important;
}
.h-scroll-sidebar{
  height: calc(100vh - 136px);
}
/* @media screen and (min-height:100vh){
  .h-scroll-sidebar{
    height: calc(100vh - 160px);
  }
} */

.bg-black{
  background-color: #000000cc !important;
}

p.line-h,.line-h p{
  line-height: 17px;
}
.text-small,.text-muted{
  line-height: normal;
}
.bg-hover-journal:hover{
  background-color: rgb(46 46 46);
}

 
.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 14px solid #fff;
}
.triangle-top {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 14px solid var(--bg-base-windows);
}

.triangle-down.black{
  
  border-top: 14px solid black;
}
.drop-shadow{

filter:drop-shadow(2px 8px 4px rgba(0,0,0,0.2))
}
.drop-shadow-none{
  filter: none !important;
}
.drop-shadow-map{

  filter:drop-shadow(2px 8px 4px rgba(0,0,0,0.7))
  }
.cls-1{
  fill: inherit !important;
}
.text-muted-darker{
  color: rgb(130, 130, 130);
}

.pt-layout{
  @apply pt-5;
}
.pl-layout{
  @apply pl-2.5;
}
.pr-layout{
  @apply pr-2.5;
}
.bg-profile{
  background: rgb(12 81 146);
}
.border-divider{
  border-color: #ccc;
}